import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import EventsDetailCovidVaccination from "../components/EventsDetailCovidVaccination";

const Covid = () => {
  return (
    <Layout pageTitle="PDJJKS | Covid Vaccination Events Detail">
      <NavOne />
      <PageHeader title="Covid Vaccination" />
      <EventsDetailCovidVaccination />
      <Footer />
    </Layout>
  );
};

export default Covid;
