import React from "react";
import Map from "./Map";
import blogImage from "../images/covid-vaccination.webp";
const EventsDetailCovidVaccination = () => {
  return (
    <section className="event-detail-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="blog-content">
              <div className="blog-item">
                <div className="blog-img">
                  <img src={blogImage} alt="" />
                  <span className="blog__tag">
                    <span className="date__num-text">9</span>
                    <span className="date__mon-text">mar</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="event-detail-content">
              <div className="event-detail-item">
                <h3 className="event__title">Covid Vaccination</h3>
                <p className="event__text">
                  Purba Dhansara Jote Jana kalyan Samity (NGO) is a
                  government-registered trust that started with the main
                  objective of rendering social welfare activities and has
                  organized free medical/health check-up camps at numerous
                  places in North Bengal, mainly to serve rural, tribal and tea
                  garden areas for the past years. One of their recent
                  initiatives is the covid vaccination camp, which aims to
                  provide free covid-19 vaccines to the underprivileged
                  population of the vicinity.
                </p>
                <p className="event__text">
                  The covid vaccination camp was held on 22nd December 2022 near
                  Ranidanga Darjeeling Public School, along with other events
                  such as voluntary blood donation camp, free eye check-up camp,
                  art competition, blanket distribution camp and social welfare
                  drama. The camp was attended by hundreds of people who
                  registered online or on the spot and received their vaccine
                  doses. The camp also provided free treatment, medicine and
                  investigation for the community.
                </p>
              </div>
              <div className="event-detail-item">
                <h3 className="event__title event__title2">
                  Event Requirements
                </h3>
                <p className="event__text">
                  The covid vaccination camp was a successful and commendable
                  effort by Purba Dhansara Jote Jana kalyan Samity (NGO) to
                  combat the pandemic and protect the lives of the people. The
                  camp was appreciated by the local authorities, media and
                  public for its noble cause and efficient execution. The camp
                  also raised awareness about the importance of vaccination and
                  covid-19 safety measures among the people. The NGO plans to
                  organize more such camps in the future to reach out to more
                  people in need.
                </p>
              </div>
              <div className="event-detail-btn">
                <a href="#none" className="theme-btn">
                  register now
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="event-detail-sidebar">
              <div className="event-detail-item">
                <h3 className="event__title">Event Details</h3>
                <ul className="event__list">
                  <li>
                    <span>Starting Time:</span> 8:00AM to 2:00PM
                  </li>
                  <li>
                    <span>Date:</span>9 March, 2019
                  </li>
                  <li>
                    <span>Category:</span>Health
                  </li>
                  <li>
                    <span>Phone:</span>+91-9933086710
                  </li>
                  <li>
                    <span>Website:</span>info@pdjjks.org
                  </li>
                  <li>
                    <span>Location:</span>Siliguri, West Bengal, India
                  </li>
                </ul>
              </div>
              <div className="event-detail-item event-detail-item2">
                <Map />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventsDetailCovidVaccination;
